// Font Awesome
@import "fa/variables";
@import "fa/path";
@import "fa/core";
@import "fa/icons";
@import "fa/larger";

// Maax
@font-face{
  font-family: "Maax";
  font-weight: 400;
  font-style:  normal;
  src: url(../../public/fonts/maax/maax-400.eot);
  src: url(../../public/fonts/maax/maax-400.eot#iefix) format('embedded-opentype'),
       url(../../public/fonts/maax/maax-400.woff) format('woff'),
       url(../../public/fonts/maax/maax-400.otf) format('opentype');
}

@font-face{
  font-family: "Maax";
  font-weight: 400;
  font-style:  italic;
  src: url(../../public/fonts/maax/maax-400-italic.eot);
  src: url(../../public/fonts/maax/maax-400-italic.eot#iefix) format('embedded-opentype'),
       url(../../public/fonts/maax/maax-400-italic.woff) format('woff'),
       url(../../public/fonts/maax/maax-400-italic.otf) format('opentype');
}

@font-face{
  font-family: "Maax";
  font-weight: 500;
  font-style:  normal;
  src: url(../../public/fonts/maax/maax-500.eot);
  src: url(../../public/fonts/maax/maax-500.eot#iefix) format('embedded-opentype'),
       url(../../public/fonts/maax/maax-500.woff) format('woff'),
       url(../../public/fonts/maax/maax-500.otf) format('opentype');
}