
// APP
#container-app {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
}

#container-map {
  flex-grow: 1;
  overflow: hidden;
  touch-action: none;
}

#container-page {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.panels {
  position: absolute;
  bottom: 0.75rem;
  right: 0.75rem;
  width: 240px;
}

.panel {
  position: relative;
  z-index: 100;
  flex-grow: 0;
  background-color: white;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  box-shadow: 0 0 0.75rem 0 rgba(0,0,0,0.1);
}

#panel-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.wrapper {
  max-width: 1080px;
  margin: 0 auto;
  padding: 1rem;

  @media (min-width: 600px) {
    padding: 2rem;
  }
}

.pane {
  background-color: #fff;
  box-shadow: 0 0 0.75rem 0 rgba(0,0,0,0.05);

  .pane-text {
    padding: 1rem;
  }
}

a.pane {
  display: block;
  text-decoration: none;

  &:hover {
    box-shadow: 0 0 0.75rem 0 rgba(0,0,0,0.2);

    h2 {
      text-decoration: underline;
    }
  }
}

.pane-header {
  position: relative;
  text-align: center;
  color: #fff;
  background-position: center;
  background-size: cover;
  min-height: 36vh;
  display: flex !important;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: rgba(0,0,0,0.75);
  }

  > * {
    position: relative;
  }

  .pane-text {
    max-width: 880px;
    padding: 3rem;
  }
}

.pane-border {
  border-top: 0.2rem var(--color-1) solid;
}

.pane-border.content {
  min-height: 55vh;
}


// COLUMNS
.row {
  margin-bottom: 2rem;
}

.columns {
  display: flex;
  flex-direction: row;
  margin: 0 -1rem;

  @media (max-width: 640px) {
    margin: 0;
    flex-direction: column
  }

  .column {
    margin: 0 1rem;
    width: calc(50% - 2rem);

    @media (max-width: 640px) {
      margin: 0 0 2rem;
      width: 100%;
    }
  }
}

// BOXES
.items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
}

.item {
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
  margin: 0.5rem;
  padding: 1rem;
  background-color: var(--color-bg);
  transition: all 200ms ease;
}


a.item {
  text-decoration: none;

  &:hover {
    background-color: var(--color-bg-hover);
    //box-shadow: 0 0 0.75rem 0 rgba(0,0,0,0.1);
  }
}

.item-title {
  font-size: 1.15em;
  text-transform: lowercase;
}

.item-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p:last-of-type{
    margin-bottom: 1rem;
  }
}