.svg-map {
  position: absolute;
  background-color: #BBD5EA;
  user-select: none;
}
.svg-map-zoom-buttons {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 0.75rem;
    padding: 0;
    font-size: 1.25rem;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    color: #444;
    background-color: white;
    border: none;
    box-shadow: 0 0 0.5rem 0 rgba(0,0,0,0.1);
    cursor: pointer;
    user-select: none;

    &:hover {
      color: #000;
    }
  }
}


.zone {
  pointer-events: none;
}

.sub-zone {
  font-size: 12px;
}

.zone-title {
  font-size: 1.778em;
  line-height: 1.2em;
  text-transform: lowercase;
  margin-bottom: 0;
  white-space: nowrap;
  transition: all 0ms ease;

  &:hover {
    text-decoration: underline;
    transition-duration: 75ms;

    .label {
      box-shadow: 0 0 0.25rem 0 rgba(0,0,0,0.2);
    }
  }
}

.zone-title-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  pointer-events: auto;
  -webkit-user-drag: none;
  transition: all 75ms ease;
  cursor: pointer;

  &:hover {
    transition: all 0ms ease;
  }
}


.themes {
  display: flex;
  flex-wrap: wrap;
}

.theme-label {
  @include label(#FFF, #000);

  font-size: 0.556em;
  margin-top: 0.3rem;
  margin-right: 0.25rem;
  -webkit-user-drag: none;
  cursor: pointer;
  transition: all 0ms ease;

  &:hover {
    box-shadow: 0 0 0.25rem 0 rgba(0,0,0,0.2);
    transition-duration: 75ms;
  }

  .theme-label-text {
    display: inline-block;
    margin-left: 0.5em;
    border-radius: 1em 0 0 1em;
    font-weight: var(--font-weight-strong);
  }
}

#easter-egg {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 6rem;
  height: 4rem;
  text-align: center;
  line-height: 4rem;
  font-size: 2rem;
  transform: translate(-1em, -1em);
  background: var(--color-bg);
  border-radius: 10px;
}
