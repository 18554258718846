.label {
  display: inline-block;
  margin: 0.25em 0;
  background-color: #000;
  border-radius: 0.75em;
  color: white;
  font-size: 0.75rem;
  height: 1.5em;
  line-height: 1.5em;
  text-transform: uppercase;
  font-weight: var(--font-weight-default);
  white-space: nowrap;
  box-sizing: border-box;
  vertical-align: middle;

  .color-1 & { background: var(--color-1); }
  .color-2 & { background: var(--color-2); }
  .color-3 & { background: var(--color-3); }
  .color-4 & { background: var(--color-4); }


  .themes .box & {
    margin-right: 0.5em;
    margin-left: 0em;
  }

  h1 & {
    font-size: 0.35em;
  }
}


.label-circle {
  width: 1.5em;
  text-align: center;

  &:first-child {
    margin-right: 0.5em;
  }

  &:last-child {
    margin-left: 0.5em;
  }

  &:first-child:last-child {
    margin-left: 0;
    margin-right: 0;
  }  
}

.label-bar {
  padding: 0 0.65em;
  margin-right: 0.5em;

  &:last-child{
    margin-right: 0;
  }
}