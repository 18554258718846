@use "sass:math";

.panel.practices {
  h4 {
    margin-top: 0.6rem;
    font-size: 0.85em;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.practice {

  #practice-body {
    display: flex;
    flex-direction: column-reverse;

    #practice-items {
      margin-top: 3rem;
      flex-shrink: 0;
      margin-right: 1rem;

      > li {
        position: relative;
        padding-bottom: 2.5rem;
        text-align: right;

        h2, .subtitle {
          cursor: pointer;
          user-select: none;
        }

        h2 {
          font-size: 1.15em;
          margin-bottom: 0;

          // station
          &:before {
            content: " ";
            position: absolute;
            top: 0.55rem;
            right: -3px;
            width: 0.5rem;
            height: 0.5rem;
            background-color: #FFF;
            border: 2px solid #000;
            border-radius: 50%;
          }
        }

        .subtitle {
          font-size: 0.9em;
        }

        .practice-item-toggle {
          display: inline-block;
          padding-right: 1.25rem;

          &:hover {
            padding-right: 1.15rem;
            padding-left: 0.1rem;
            transition: all 100ms ease;
  
            h2:before {
              transform: scale(1.1);
              transition: all 100ms ease;
            }
          }
        }


        // track
        &:before {
          content: " ";
          position: absolute;
          top: 0.55rem;
          right: 0;
          width: 6px;
          height: 100%;
          background-color: var(--color-1);
        }
        &:last-child::before {
          content: none;
        }
      }
    }


    #practice-item {
      background-color: var(--color-bg-hover);
      padding: 1rem;
      min-height: 30vh;
      text-align: left;

      .button-close {
        position: absolute;
        top: 0.15rem;
        right: 0.15rem;
        width: $button-size;
        height: $button-size;
        padding: 0 math.div(($button-size - $button-icon-size), 2);
        cursor: pointer;

        .icon {
          width: $button-icon-size;
          transition: all 100ms ease;
        }
      }
    }
  }

  @media (max-width: 719px) {
    #practice-item {
      position: relative;
      width: calc(100% - 1.25rem) !important;
      margin: 1rem 0;

      // arrow up
      &:after {
        content: " ";
        position: absolute;
        bottom: 100%;
        right: 20px;
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent; 
        border-bottom: 10px solid var(--color-bg-hover); 
      }
    }
  }

  @media (min-width: 720px) {

    #practice-body {
      flex-direction: row;
    }

    #practice-intro {
      margin-top: 3rem;
      padding: 0 1.5rem;
    }

    #practice-items {
      width: 30%;
      min-width: 300px;
    }

    #practice-item {
      position: absolute;
      top: -86px;
      left: calc(100% + 20px);
      margin-bottom: 2rem;
      //box-shadow: 0 0 0.5rem 0 rgba(0,0,0,0.2);

      // arrow left
      &:after {
        content: " ";
        position: absolute;
        top: 90px;
        right: 100%;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid var(--color-bg-hover);
      }
    }
  }
}