#footer {
  margin-top: 4rem;
  background-color: white;
  box-shadow: 0 0 0.75rem 0 rgba(0,0,0,0.1);

  table {
    margin-bottom: 2rem;
  }

  tr {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    td {
      min-width: 180px;

      img {
        margin: 0 auto;
        width: auto;
        height: auto;
        max-width: 180px;
        max-height: 125px;
      }
    }
  }

  small {
    img {
      margin: 0 auto 0.5em;
    }

    display: block;
    max-width: 600px;
    margin: 3rem auto 0;
    text-align: center;
  }
}
