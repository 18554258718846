// RESETS
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  text-size-adjust: none;
}

iframe {
  max-width: 100%;
  border: none;
}

figure {
  margin: 0;
  max-width: 100%;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  outline: none;
  border: none;
}

svg {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  //height: auto;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

* {
  box-sizing: border-box;
}

.no-select {
  user-select: none;
}

:focus {
  outline: 0;
}
