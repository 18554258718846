a {
  box-sizing: border-box;
  color: var(--color-text);
  transition: color 100ms ease;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.button,
input[type=submit],
input[type=button] {
  position: relative;
  display: inline-block;
  line-height: 1.65em;
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;
  padding: 0.15em 0.6em 0.125em;
  background: var(--color-1);
  border: none;
  border-radius: 2px;
  color: #FFF;
  font-family: var(--font-family-default);
  font-size: 1rem;
  vertical-align: top;
  font-weight: var(--font-weight-default);
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0.25rem 0 rgba(0,0,0,0.5);
  }

  .color-1 & { background: var(--color-1); }
  .color-2 & { background: var(--color-2); }
  .color-3 & { background: var(--color-3); }
  .color-4 & { background: var(--color-4); }


  &:after {
    content: " ";
    position: absolute;
    left: 0%;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: rgba(0,0,0,0.25);
    border-radius: 0 0 2px 2px;
    transition: width 0ms ease;
  }
  &:hover:after,
  &:focus:after {
    width: 100%;
    transition: all 200ms ease;
  }
}

.button-small {
  position: relative;
  display: inline-block;
  line-height: 1.55em;
  padding: 0.15em 0.5em;
  background: var(--color-1);
  border: none;
  border-radius: 2px;
  color: #fff;
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: var(--font-weight-default);
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  &:after {
    content: " ";
    position: absolute;
    left: 0%;
    bottom: 0;
    height: 2px;
    width: 0;
    background-color: rgba(0,0,0,0.25);
    border-radius: 0 0 2px 2px;
    transition: width 0ms ease;
  }
  &:hover:after {
    width: 100%;
    transition: all 200ms ease;
  }
}

.button-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: var(--color-1);

  &:hover {
    text-decoration: none;
  }
  &:after {
    content: " ";
    position: absolute;
    left: 0%;
    bottom: 0;
    height: 1px;
    width: 0;
    background-color: rgba(0,0,0,0.25);
    transition: width 0ms ease;
  }
  &:hover:after {
    width: 100%;
    transition: all 100ms ease;
  }
}

.button-inline {
  margin-right: -1em;
}

.button,
.button-link,
.button-small,
button,
input[type=submit],
input[type=button] {

  &:before {
    font-family: "FontAwesome";
    margin-right: 0.35rem;
    line-height: 1rem;
    vertical-align: baseline;
    font-size: 0.75em;
  }

  &.button-false {
    background-color: var(--color-false) !important;

    &:after {
      background-color: rgba(0,0,0,0.25) !important;
    }
  }

  &.button-check:before    { content: ""; }
  &.button-reject:before   { content: ""; }
  &.button-add:before      { content: ""; }
  &.button-lock:before     { content: ""; }
  &.button-unlock:before   { content: ""; }
  &.button-done:before     { content: ""; }
  &.button-prev:before     { content: ""; }
  &.button-next:before     { content: ""; }
  &.button-save:before     { content: ""; }
  &.button-edit:before     { content: ""; }
  &.button-info:before     { content: ""; }
  &.button-export:before   { content: ""; }
  &.button-refresh:before  { content: ""; }
  &.button-calendar:before { content: ""; }
  &.button-delete:before { content: ""; }

  &.button-download:before { content: $fa-var-download; }
  &.button-clear:before    { content: $fa-var-times; }
  &.button-go:before       { content: $fa-var-angle-right; }
}