html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  text-size-adjust: none;
}

iframe {
  max-width: 100%;
  border: none;
}

figure {
  margin: 0;
  max-width: 100%;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  outline: none;
  border: none;
}

svg {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

table {
  border-collapse: collapse;
  width: 100%;
}

* {
  box-sizing: border-box;
}

.no-select {
  user-select: none;
}

:focus {
  outline: 0;
}

/* FONT PATH
 * -------------------------- */
@font-face {
  font-family: "FontAwesome";
  src: url("../../public/fonts/fa/fontawesome-webfont.eot?v=4.6.3");
  src: url("../../public/fonts/fa/fontawesome-webfont.eot?#iefix&v=4.6.3") format("embedded-opentype"), url("../../public/fonts/fa/fontawesome-webfont.woff2?v=4.6.3") format("woff2"), url("../../public/fonts/fa/fontawesome-webfont.woff?v=4.6.3") format("woff"), url("../../public/fonts/fa/fontawesome-webfont.ttf?v=4.6.3") format("truetype"), url("../../public/fonts/fa/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: "\f000";
}

.fa-music:before {
  content: "\f001";
}

.fa-search:before {
  content: "\f002";
}

.fa-envelope-o:before {
  content: "\f003";
}

.fa-heart:before {
  content: "\f004";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-o:before {
  content: "\f006";
}

.fa-user:before {
  content: "\f007";
}

.fa-film:before {
  content: "\f008";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-check:before {
  content: "\f00c";
}

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-signal:before {
  content: "\f012";
}

.fa-gear:before,
.fa-cog:before {
  content: "\f013";
}

.fa-trash-o:before {
  content: "\f014";
}

.fa-home:before {
  content: "\f015";
}

.fa-file-o:before {
  content: "\f016";
}

.fa-clock-o:before {
  content: "\f017";
}

.fa-road:before {
  content: "\f018";
}

.fa-download:before {
  content: "\f019";
}

.fa-arrow-circle-o-down:before {
  content: "\f01a";
}

.fa-arrow-circle-o-up:before {
  content: "\f01b";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-play-circle-o:before {
  content: "\f01d";
}

.fa-rotate-right:before,
.fa-repeat:before {
  content: "\f01e";
}

.fa-refresh:before {
  content: "\f021";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-lock:before {
  content: "\f023";
}

.fa-flag:before {
  content: "\f024";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-print:before {
  content: "\f02f";
}

.fa-camera:before {
  content: "\f030";
}

.fa-font:before {
  content: "\f031";
}

.fa-bold:before {
  content: "\f032";
}

.fa-italic:before {
  content: "\f033";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-list:before {
  content: "\f03a";
}

.fa-dedent:before,
.fa-outdent:before {
  content: "\f03b";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-video-camera:before {
  content: "\f03d";
}

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: "\f03e";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-tint:before {
  content: "\f043";
}

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\f044";
}

.fa-share-square-o:before {
  content: "\f045";
}

.fa-check-square-o:before {
  content: "\f046";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-play:before {
  content: "\f04b";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-eject:before {
  content: "\f052";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-times-circle-o:before {
  content: "\f05c";
}

.fa-check-circle-o:before {
  content: "\f05d";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-mail-forward:before,
.fa-share:before {
  content: "\f064";
}

.fa-expand:before {
  content: "\f065";
}

.fa-compress:before {
  content: "\f066";
}

.fa-plus:before {
  content: "\f067";
}

.fa-minus:before {
  content: "\f068";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-plane:before {
  content: "\f072";
}

.fa-calendar:before {
  content: "\f073";
}

.fa-random:before {
  content: "\f074";
}

.fa-comment:before {
  content: "\f075";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\f080";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-key:before {
  content: "\f084";
}

.fa-gears:before,
.fa-cogs:before {
  content: "\f085";
}

.fa-comments:before {
  content: "\f086";
}

.fa-thumbs-o-up:before {
  content: "\f087";
}

.fa-thumbs-o-down:before {
  content: "\f088";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-heart-o:before {
  content: "\f08a";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-linkedin-square:before {
  content: "\f08c";
}

.fa-thumb-tack:before {
  content: "\f08d";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-upload:before {
  content: "\f093";
}

.fa-lemon-o:before {
  content: "\f094";
}

.fa-phone:before {
  content: "\f095";
}

.fa-square-o:before {
  content: "\f096";
}

.fa-bookmark-o:before {
  content: "\f097";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-facebook-f:before,
.fa-facebook:before {
  content: "\f09a";
}

.fa-github:before {
  content: "\f09b";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-feed:before,
.fa-rss:before {
  content: "\f09e";
}

.fa-hdd-o:before {
  content: "\f0a0";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-hand-o-right:before {
  content: "\f0a4";
}

.fa-hand-o-left:before {
  content: "\f0a5";
}

.fa-hand-o-up:before {
  content: "\f0a6";
}

.fa-hand-o-down:before {
  content: "\f0a7";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-group:before,
.fa-users:before {
  content: "\f0c0";
}

.fa-chain:before,
.fa-link:before {
  content: "\f0c1";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-cut:before,
.fa-scissors:before {
  content: "\f0c4";
}

.fa-copy:before,
.fa-files-o:before {
  content: "\f0c5";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-save:before,
.fa-floppy-o:before {
  content: "\f0c7";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: "\f0c9";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-plus:before {
  content: "\f0d5";
}

.fa-money:before {
  content: "\f0d6";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-unsorted:before,
.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-down:before,
.fa-sort-desc:before {
  content: "\f0dd";
}

.fa-sort-up:before,
.fa-sort-asc:before {
  content: "\f0de";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-linkedin:before {
  content: "\f0e1";
}

.fa-rotate-left:before,
.fa-undo:before {
  content: "\f0e2";
}

.fa-legal:before,
.fa-gavel:before {
  content: "\f0e3";
}

.fa-dashboard:before,
.fa-tachometer:before {
  content: "\f0e4";
}

.fa-comment-o:before {
  content: "\f0e5";
}

.fa-comments-o:before {
  content: "\f0e6";
}

.fa-flash:before,
.fa-bolt:before {
  content: "\f0e7";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-paste:before,
.fa-clipboard:before {
  content: "\f0ea";
}

.fa-lightbulb-o:before {
  content: "\f0eb";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-bell-o:before {
  content: "\f0a2";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cutlery:before {
  content: "\f0f5";
}

.fa-file-text-o:before {
  content: "\f0f6";
}

.fa-building-o:before {
  content: "\f0f7";
}

.fa-hospital-o:before {
  content: "\f0f8";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\f10b";
}

.fa-circle-o:before {
  content: "\f10c";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-circle:before {
  content: "\f111";
}

.fa-mail-reply:before,
.fa-reply:before {
  content: "\f112";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-folder-o:before {
  content: "\f114";
}

.fa-folder-open-o:before {
  content: "\f115";
}

.fa-smile-o:before {
  content: "\f118";
}

.fa-frown-o:before {
  content: "\f119";
}

.fa-meh-o:before {
  content: "\f11a";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-keyboard-o:before {
  content: "\f11c";
}

.fa-flag-o:before {
  content: "\f11d";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-code:before {
  content: "\f121";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\f122";
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\f123";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-crop:before {
  content: "\f125";
}

.fa-code-fork:before {
  content: "\f126";
}

.fa-unlink:before,
.fa-chain-broken:before {
  content: "\f127";
}

.fa-question:before {
  content: "\f128";
}

.fa-info:before {
  content: "\f129";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-shield:before {
  content: "\f132";
}

.fa-calendar-o:before {
  content: "\f133";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-minus-square-o:before {
  content: "\f147";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-pencil-square:before {
  content: "\f14b";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: "\f150";
}

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: "\f151";
}

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: "\f152";
}

.fa-euro:before,
.fa-eur:before {
  content: "\f153";
}

.fa-gbp:before {
  content: "\f154";
}

.fa-dollar:before,
.fa-usd:before {
  content: "\f155";
}

.fa-rupee:before,
.fa-inr:before {
  content: "\f156";
}

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: "\f157";
}

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: "\f158";
}

.fa-won:before,
.fa-krw:before {
  content: "\f159";
}

.fa-bitcoin:before,
.fa-btc:before {
  content: "\f15a";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-text:before {
  content: "\f15c";
}

.fa-sort-alpha-asc:before {
  content: "\f15d";
}

.fa-sort-alpha-desc:before {
  content: "\f15e";
}

.fa-sort-amount-asc:before {
  content: "\f160";
}

.fa-sort-amount-desc:before {
  content: "\f161";
}

.fa-sort-numeric-asc:before {
  content: "\f162";
}

.fa-sort-numeric-desc:before {
  content: "\f163";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-youtube-square:before {
  content: "\f166";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-youtube-play:before {
  content: "\f16a";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-adn:before {
  content: "\f170";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitbucket-square:before {
  content: "\f172";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-apple:before {
  content: "\f179";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-android:before {
  content: "\f17b";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-trello:before {
  content: "\f181";
}

.fa-female:before {
  content: "\f182";
}

.fa-male:before {
  content: "\f183";
}

.fa-gittip:before,
.fa-gratipay:before {
  content: "\f184";
}

.fa-sun-o:before {
  content: "\f185";
}

.fa-moon-o:before {
  content: "\f186";
}

.fa-archive:before {
  content: "\f187";
}

.fa-bug:before {
  content: "\f188";
}

.fa-vk:before {
  content: "\f189";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-arrow-circle-o-right:before {
  content: "\f18e";
}

.fa-arrow-circle-o-left:before {
  content: "\f190";
}

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: "\f191";
}

.fa-dot-circle-o:before {
  content: "\f192";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-turkish-lira:before,
.fa-try:before {
  content: "\f195";
}

.fa-plus-square-o:before {
  content: "\f196";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-slack:before {
  content: "\f198";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: "\f19c";
}

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-spoon:before {
  content: "\f1b1";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-automobile:before,
.fa-car:before {
  content: "\f1b9";
}

.fa-cab:before,
.fa-taxi:before {
  content: "\f1ba";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-file-pdf-o:before {
  content: "\f1c1";
}

.fa-file-word-o:before {
  content: "\f1c2";
}

.fa-file-excel-o:before {
  content: "\f1c3";
}

.fa-file-powerpoint-o:before {
  content: "\f1c4";
}

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: "\f1c5";
}

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: "\f1c6";
}

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: "\f1c7";
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\f1c8";
}

.fa-file-code-o:before {
  content: "\f1c9";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: "\f1cd";
}

.fa-circle-o-notch:before {
  content: "\f1ce";
}

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: "\f1d0";
}

.fa-ge:before,
.fa-empire:before {
  content: "\f1d1";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-wechat:before,
.fa-weixin:before {
  content: "\f1d7";
}

.fa-send:before,
.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: "\f1d9";
}

.fa-history:before {
  content: "\f1da";
}

.fa-circle-thin:before {
  content: "\f1db";
}

.fa-header:before {
  content: "\f1dc";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-sliders:before {
  content: "\f1de";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: "\f1e3";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-newspaper-o:before {
  content: "\f1ea";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bell-slash-o:before {
  content: "\f1f7";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-eyedropper:before {
  content: "\f1fb";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-area-chart:before {
  content: "\f1fe";
}

.fa-pie-chart:before {
  content: "\f200";
}

.fa-line-chart:before {
  content: "\f201";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bus:before {
  content: "\f207";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-cc:before {
  content: "\f20a";
}

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: "\f20b";
}

.fa-meanpath:before {
  content: "\f20c";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-venus:before {
  content: "\f221";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-intersex:before,
.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-facebook-official:before {
  content: "\f230";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-server:before {
  content: "\f233";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-hotel:before,
.fa-bed:before {
  content: "\f236";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-train:before {
  content: "\f238";
}

.fa-subway:before {
  content: "\f239";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-yc:before,
.fa-y-combinator:before {
  content: "\f23b";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-battery-4:before,
.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battery-2:before,
.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\f244";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-sticky-note-o:before {
  content: "\f24a";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-hourglass-o:before {
  content: "\f250";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\f251";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\f255";
}

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: "\f256";
}

.fa-hand-scissors-o:before {
  content: "\f257";
}

.fa-hand-lizard-o:before {
  content: "\f258";
}

.fa-hand-spock-o:before {
  content: "\f259";
}

.fa-hand-pointer-o:before {
  content: "\f25a";
}

.fa-hand-peace-o:before {
  content: "\f25b";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-safari:before {
  content: "\f267";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-tv:before,
.fa-television:before {
  content: "\f26c";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-500px:before {
  content: "\f26e";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-calendar-plus-o:before {
  content: "\f271";
}

.fa-calendar-minus-o:before {
  content: "\f272";
}

.fa-calendar-times-o:before {
  content: "\f273";
}

.fa-calendar-check-o:before {
  content: "\f274";
}

.fa-industry:before {
  content: "\f275";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-map-o:before {
  content: "\f278";
}

.fa-map:before {
  content: "\f279";
}

.fa-commenting:before {
  content: "\f27a";
}

.fa-commenting-o:before {
  content: "\f27b";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-vimeo:before {
  content: "\f27d";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-edge:before {
  content: "\f282";
}

.fa-credit-card-alt:before {
  content: "\f283";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-modx:before {
  content: "\f285";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-usb:before {
  content: "\f287";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-pause-circle-o:before {
  content: "\f28c";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stop-circle-o:before {
  content: "\f28e";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-percent:before {
  content: "\f295";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-envira:before {
  content: "\f299";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-wheelchair-alt:before {
  content: "\f29b";
}

.fa-question-circle-o:before {
  content: "\f29c";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-volume-control-phone:before {
  content: "\f2a0";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: "\f2a4";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-signing:before,
.fa-sign-language:before {
  content: "\f2a7";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\f2b3";
}

.fa-fa:before,
.fa-font-awesome:before {
  content: "\f2b4";
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

@font-face {
  font-family: "Maax";
  font-weight: 400;
  font-style: normal;
  src: url(../../public/fonts/maax/maax-400.eot);
  src: url(../../public/fonts/maax/maax-400.eot#iefix) format("embedded-opentype"), url(../../public/fonts/maax/maax-400.woff) format("woff"), url(../../public/fonts/maax/maax-400.otf) format("opentype");
}
@font-face {
  font-family: "Maax";
  font-weight: 400;
  font-style: italic;
  src: url(../../public/fonts/maax/maax-400-italic.eot);
  src: url(../../public/fonts/maax/maax-400-italic.eot#iefix) format("embedded-opentype"), url(../../public/fonts/maax/maax-400-italic.woff) format("woff"), url(../../public/fonts/maax/maax-400-italic.otf) format("opentype");
}
@font-face {
  font-family: "Maax";
  font-weight: 500;
  font-style: normal;
  src: url(../../public/fonts/maax/maax-500.eot);
  src: url(../../public/fonts/maax/maax-500.eot#iefix) format("embedded-opentype"), url(../../public/fonts/maax/maax-500.woff) format("woff"), url(../../public/fonts/maax/maax-500.otf) format("opentype");
}
body {
  margin: 0;
  background-color: var(--color-bg);
  font-family: var(--font-family-default);
  font-weight: var(--font-weight-default);
  font-size: 16px;
  color: var(--color-text);
}
@media (min-width: 600px) {
  body {
    font-size: 17px;
  }
}

em {
  font-style: italic;
}

strong, th {
  font-weight: var(--font-weight-strong);
}

h1, h2, h3, h4, h5 {
  font-family: var(--font-family-headings);
  font-weight: var(--font-weight-headings);
  color: var(--color-headings);
  margin: 0;
}
h1 .icon, h2 .icon, h3 .icon, h4 .icon, h5 .icon {
  font-family: "FontAwesome";
  margin-right: 0.5em;
  vertical-align: middle;
  font-size: 0.9em;
}
h1.subtitle, h2.subtitle, h3.subtitle, h4.subtitle, h5.subtitle {
  font-family: var(--font-family-default);
  font-weight: var(--font-weight-default);
  margin-bottom: 1rem;
}

h1 {
  font-size: 2em;
  line-height: 1.25em;
  margin-bottom: 1em;
}

h2 {
  font-size: 1.3em;
  margin-bottom: 1em;
  line-height: 1.25em;
}

h3, h4, h5 {
  font-size: 1em;
  line-height: 1.35em;
}

p {
  margin: 0 0 1.5em;
  line-height: 1.5em;
}
p:last-child {
  margin-bottom: 0;
}

small,
.small {
  font-size: 0.8em;
  line-height: 1.35em;
}

.description {
  margin-bottom: 2em;
}

.nowrap {
  white-space: nowrap;
}

:focus {
  outline: 0;
}

::selection {
  background: #000;
  color: #FFF;
}

.fa-icon {
  display: inline-block;
  font-family: "FontAwesome";
  transition: all 100ms ease;
}

a {
  box-sizing: border-box;
  color: var(--color-text);
  transition: color 100ms ease;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}

.button,
input[type=submit],
input[type=button] {
  position: relative;
  display: inline-block;
  line-height: 1.65em;
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;
  padding: 0.15em 0.6em 0.125em;
  background: var(--color-1);
  border: none;
  border-radius: 2px;
  color: #FFF;
  font-family: var(--font-family-default);
  font-size: 1rem;
  vertical-align: top;
  font-weight: var(--font-weight-default);
  text-decoration: none;
  user-select: none;
  cursor: pointer;
}
.button:focus,
input[type=submit]:focus,
input[type=button]:focus {
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.5);
}
.color-1 .button,
.color-1 input[type=submit],
.color-1 input[type=button] {
  background: var(--color-1);
}
.color-2 .button,
.color-2 input[type=submit],
.color-2 input[type=button] {
  background: var(--color-2);
}
.color-3 .button,
.color-3 input[type=submit],
.color-3 input[type=button] {
  background: var(--color-3);
}
.color-4 .button,
.color-4 input[type=submit],
.color-4 input[type=button] {
  background: var(--color-4);
}
.button:after,
input[type=submit]:after,
input[type=button]:after {
  content: " ";
  position: absolute;
  left: 0%;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 0 0 2px 2px;
  transition: width 0ms ease;
}
.button:hover:after, .button:focus:after,
input[type=submit]:hover:after,
input[type=submit]:focus:after,
input[type=button]:hover:after,
input[type=button]:focus:after {
  width: 100%;
  transition: all 200ms ease;
}

.button-small {
  position: relative;
  display: inline-block;
  line-height: 1.55em;
  padding: 0.15em 0.5em;
  background: var(--color-1);
  border: none;
  border-radius: 2px;
  color: #fff;
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: var(--font-weight-default);
  text-decoration: none;
  user-select: none;
  cursor: pointer;
}
.button-small:after {
  content: " ";
  position: absolute;
  left: 0%;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 0 0 2px 2px;
  transition: width 0ms ease;
}
.button-small:hover:after {
  width: 100%;
  transition: all 200ms ease;
}

.button-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: var(--color-1);
}
.button-link:hover {
  text-decoration: none;
}
.button-link:after {
  content: " ";
  position: absolute;
  left: 0%;
  bottom: 0;
  height: 1px;
  width: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: width 0ms ease;
}
.button-link:hover:after {
  width: 100%;
  transition: all 100ms ease;
}

.button-inline {
  margin-right: -1em;
}

.button:before,
.button-link:before,
.button-small:before,
button:before,
input[type=submit]:before,
input[type=button]:before {
  font-family: "FontAwesome";
  margin-right: 0.35rem;
  line-height: 1rem;
  vertical-align: baseline;
  font-size: 0.75em;
}
.button.button-false,
.button-link.button-false,
.button-small.button-false,
button.button-false,
input[type=submit].button-false,
input[type=button].button-false {
  background-color: var(--color-false) !important;
}
.button.button-false:after,
.button-link.button-false:after,
.button-small.button-false:after,
button.button-false:after,
input[type=submit].button-false:after,
input[type=button].button-false:after {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.button.button-check:before,
.button-link.button-check:before,
.button-small.button-check:before,
button.button-check:before,
input[type=submit].button-check:before,
input[type=button].button-check:before {
  content: "\f00c";
}
.button.button-reject:before,
.button-link.button-reject:before,
.button-small.button-reject:before,
button.button-reject:before,
input[type=submit].button-reject:before,
input[type=button].button-reject:before {
  content: "\f00d";
}
.button.button-add:before,
.button-link.button-add:before,
.button-small.button-add:before,
button.button-add:before,
input[type=submit].button-add:before,
input[type=button].button-add:before {
  content: "\f067";
}
.button.button-lock:before,
.button-link.button-lock:before,
.button-small.button-lock:before,
button.button-lock:before,
input[type=submit].button-lock:before,
input[type=button].button-lock:before {
  content: "\f023";
}
.button.button-unlock:before,
.button-link.button-unlock:before,
.button-small.button-unlock:before,
button.button-unlock:before,
input[type=submit].button-unlock:before,
input[type=button].button-unlock:before {
  content: "\f09c";
}
.button.button-done:before,
.button-link.button-done:before,
.button-small.button-done:before,
button.button-done:before,
input[type=submit].button-done:before,
input[type=button].button-done:before {
  content: "\f00c";
}
.button.button-prev:before,
.button-link.button-prev:before,
.button-small.button-prev:before,
button.button-prev:before,
input[type=submit].button-prev:before,
input[type=button].button-prev:before {
  content: "\f104";
}
.button.button-next:before,
.button-link.button-next:before,
.button-small.button-next:before,
button.button-next:before,
input[type=submit].button-next:before,
input[type=button].button-next:before {
  content: "\f105";
}
.button.button-save:before,
.button-link.button-save:before,
.button-small.button-save:before,
button.button-save:before,
input[type=submit].button-save:before,
input[type=button].button-save:before {
  content: "\f00c";
}
.button.button-edit:before,
.button-link.button-edit:before,
.button-small.button-edit:before,
button.button-edit:before,
input[type=submit].button-edit:before,
input[type=button].button-edit:before {
  content: "\f040";
}
.button.button-info:before,
.button-link.button-info:before,
.button-small.button-info:before,
button.button-info:before,
input[type=submit].button-info:before,
input[type=button].button-info:before {
  content: "\f129";
}
.button.button-export:before,
.button-link.button-export:before,
.button-small.button-export:before,
button.button-export:before,
input[type=submit].button-export:before,
input[type=button].button-export:before {
  content: "\f0ce";
}
.button.button-refresh:before,
.button-link.button-refresh:before,
.button-small.button-refresh:before,
button.button-refresh:before,
input[type=submit].button-refresh:before,
input[type=button].button-refresh:before {
  content: "\f021";
}
.button.button-calendar:before,
.button-link.button-calendar:before,
.button-small.button-calendar:before,
button.button-calendar:before,
input[type=submit].button-calendar:before,
input[type=button].button-calendar:before {
  content: "\f073";
}
.button.button-delete:before,
.button-link.button-delete:before,
.button-small.button-delete:before,
button.button-delete:before,
input[type=submit].button-delete:before,
input[type=button].button-delete:before {
  content: "\f1f8";
}
.button.button-download:before,
.button-link.button-download:before,
.button-small.button-download:before,
button.button-download:before,
input[type=submit].button-download:before,
input[type=button].button-download:before {
  content: "\f019";
}
.button.button-clear:before,
.button-link.button-clear:before,
.button-small.button-clear:before,
button.button-clear:before,
input[type=submit].button-clear:before,
input[type=button].button-clear:before {
  content: "\f00d";
}
.button.button-go:before,
.button-link.button-go:before,
.button-small.button-go:before,
button.button-go:before,
input[type=submit].button-go:before,
input[type=button].button-go:before {
  content: "\f105";
}

input {
  font-family: "Maax", sans-serif;
  background-color: var(--color-bg);
  border: none;
  border-bottom: 2px solid var(--color-bg);
  margin-bottom: 0.5rem;
  padding: 0.8em 0.8em 0.6em;
  transition: all 200ms ease;
}
input :focus {
  border-bottom-color: var(--color-bg);
}

button {
  background-color: var(--color-1);
  color: #FFF;
  padding: 0.8em 0.8em 0.6em;
  border: none;
  text-transform: uppercase;
}

.form-login {
  display: flex;
  flex-direction: column;
  max-width: 14rem;
  margin: 1rem 0;
}

.label {
  display: inline-block;
  margin: 0.25em 0;
  background-color: #000;
  border-radius: 0.75em;
  color: white;
  font-size: 0.75rem;
  height: 1.5em;
  line-height: 1.5em;
  text-transform: uppercase;
  font-weight: var(--font-weight-default);
  white-space: nowrap;
  box-sizing: border-box;
  vertical-align: middle;
}
.color-1 .label {
  background: var(--color-1);
}
.color-2 .label {
  background: var(--color-2);
}
.color-3 .label {
  background: var(--color-3);
}
.color-4 .label {
  background: var(--color-4);
}
.themes .box .label {
  margin-right: 0.5em;
  margin-left: 0em;
}
h1 .label {
  font-size: 0.35em;
}

.label-circle {
  width: 1.5em;
  text-align: center;
}
.label-circle:first-child {
  margin-right: 0.5em;
}
.label-circle:last-child {
  margin-left: 0.5em;
}
.label-circle:first-child:last-child {
  margin-left: 0;
  margin-right: 0;
}

.label-bar {
  padding: 0 0.65em;
  margin-right: 0.5em;
}
.label-bar:last-child {
  margin-right: 0;
}

.button-back {
  position: absolute;
  text-align: center;
  color: #000;
  font-size: 1rem;
  line-height: 1.15rem;
  user-select: none;
  cursor: pointer;
  transition: all 100ms ease;
}
.button-back:hover .fa-icon {
  transform: translateX(-1px);
}

#buttons-bar {
  height: 1.8rem;
}
#buttons-bar > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.color-1 #buttons-bar > div {
  background: var(--color-1);
}
.color-2 #buttons-bar > div {
  background: var(--color-2);
}
.color-3 #buttons-bar > div {
  background: var(--color-3);
}
.color-4 #buttons-bar > div {
  background: var(--color-4);
}

.bar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 1.8rem;
  padding: 0 0.575rem;
  color: #FFF;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
}
.bar-button .text {
  font-size: 0.85em;
  margin-top: 0.2em;
  padding: 0 0.2875rem;
}
.bar-button .icon {
  width: 0.65rem;
  transition: all 100ms ease;
}
.bar-button .icon path {
  fill: #FFF;
}

.button-back {
  left: 0;
}
.button-back:hover .icon {
  transform: translateX(-1px);
}

.button-close {
  right: 0;
  transform: rotate(90deg);
}
.button-close:hover .icon {
  transform: rotate(180deg);
}

.button-collapse {
  right: 0;
}
.button-collapse:hover .icon {
  transform: translateX(-1px);
}
.collapsed .button-collapse {
  transform: rotate(180deg);
}
@media (max-width: 760px) {
  .button-collapse {
    display: none;
  }
}

.scrollable {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.025);
}
.scrollable::-webkit-scrollbar {
  width: 0.4rem;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}
.scrollable::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.025);
}

.toggle .toggle-button {
  position: relative;
  padding-right: 1.25em;
  cursor: pointer;
  user-select: none;
}
.toggle .toggle-button:after {
  content: "\f106";
  font-family: "FontAwesome";
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: transform 0ms ease;
}
.toggle .toggle-button:hover:after {
  transform: translateY(-1px);
  transition-duration: 75ms;
}
.toggle.toggled .toggle-button:after {
  content: "\f107";
}
.toggle.toggled .toggle-button:hover:after {
  transform: translateY(1px);
}
.toggle .toggle-content {
  max-height: 0;
  overflow: hidden;
  transition: all 100ms ease;
}
.toggle.toggled .toggle-content {
  max-height: 600px;
  transition: all 200ms ease;
}

#container-app {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
}

#container-map {
  flex-grow: 1;
  overflow: hidden;
  touch-action: none;
}

#container-page {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.panels {
  position: absolute;
  bottom: 0.75rem;
  right: 0.75rem;
  width: 240px;
}

.panel {
  position: relative;
  z-index: 100;
  flex-grow: 0;
  background-color: white;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
}

#panel-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.wrapper {
  max-width: 1080px;
  margin: 0 auto;
  padding: 1rem;
}
@media (min-width: 600px) {
  .wrapper {
    padding: 2rem;
  }
}

.pane {
  background-color: #fff;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.05);
}
.pane .pane-text {
  padding: 1rem;
}

a.pane {
  display: block;
  text-decoration: none;
}
a.pane:hover {
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.2);
}
a.pane:hover h2 {
  text-decoration: underline;
}

.pane-header {
  position: relative;
  text-align: center;
  color: #fff;
  background-position: center;
  background-size: cover;
  min-height: 36vh;
  display: flex !important;
  align-items: center;
}
.pane-header:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
}
.pane-header > * {
  position: relative;
}
.pane-header .pane-text {
  max-width: 880px;
  padding: 3rem;
}

.pane-border {
  border-top: 0.2rem var(--color-1) solid;
}

.pane-border.content {
  min-height: 55vh;
}

.row {
  margin-bottom: 2rem;
}

.columns {
  display: flex;
  flex-direction: row;
  margin: 0 -1rem;
}
@media (max-width: 640px) {
  .columns {
    margin: 0;
    flex-direction: column;
  }
}
.columns .column {
  margin: 0 1rem;
  width: calc(50% - 2rem);
}
@media (max-width: 640px) {
  .columns .column {
    margin: 0 0 2rem;
    width: 100%;
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
}

.item {
  display: flex;
  flex-direction: column;
  width: calc(100% - 1rem);
  margin: 0.5rem;
  padding: 1rem;
  background-color: var(--color-bg);
  transition: all 200ms ease;
}

a.item {
  text-decoration: none;
}
a.item:hover {
  background-color: var(--color-bg-hover);
}

.item-title {
  font-size: 1.15em;
  text-transform: lowercase;
}

.item-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item-body p:last-of-type {
  margin-bottom: 1rem;
}

#site-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 0.75rem;
  z-index: 101;
  background-color: white;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
}

#site-title {
  font-size: 1.5em;
  margin-top: 0.75rem;
  margin-bottom: 0;
}

#site-subtitle {
  font-size: 0.9em;
  margin-bottom: 0.75rem;
  line-height: 1.4rem;
  font-weight: var(--font-weight-default);
}

#nav-toggle {
  position: absolute;
  top: 1.2rem;
  right: 0.75rem;
  cursor: pointer;
  user-select: none;
}
#nav-toggle .icon {
  transition: all 200ms ease;
  font-size: 1.15em;
}
.nav-toggled #nav-toggle .icon {
  transform: rotate(-90deg);
}

@media (min-width: 760px) {
  #site-navigation #nav-toggle {
    display: none;
  }
  #site-navigation nav {
    display: flex;
    flex-direction: column-reverse;
    transition: all 100ms ease;
    z-index: 200;
  }
  #site-navigation nav > ul {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.25rem;
    line-height: 3rem;
  }
  #site-navigation nav > ul a,
#site-navigation nav > ul span {
    display: block;
    margin: 0 1em;
    color: var(--color-text);
    text-decoration: none;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  #site-navigation nav > ul a {
    position: relative;
  }
  #site-navigation nav > ul a:after {
    content: " ";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    height: 0.2rem;
    width: 0;
    background-color: var(--color-1);
    transition: width 0ms ease;
  }
  #site-navigation nav > ul a:hover:after,
#site-navigation nav > ul .current a:not([href*="#"]):after,
#site-navigation nav > ul .current a:not([href*="#"]):after {
    width: 100%;
    transition: all 150ms ease;
  }
  #site-navigation nav .languages {
    margin: 0 0.75rem;
  }
  #site-navigation nav .languages .icon {
    position: relative;
    top: 0.15em;
    font-size: 0.9em;
  }
  #site-navigation nav .languages a,
#site-navigation nav .languages span {
    position: relative;
    display: inline-block;
    margin: 0 0.25rem;
  }
  #site-navigation nav .languages a:hover,
#site-navigation nav .languages span:hover {
    text-decoration: none !important;
  }
  #site-navigation nav .languages .lang {
    position: relative;
  }
  #site-navigation nav .languages .lang.current {
    font-weight: var(--font-weight-strong);
  }
  #site-navigation nav .languages .lang:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.6em;
    width: 0;
    height: 2px;
    background-color: var(--color-text);
    transition: width 150ms ease;
  }
  #site-navigation nav .languages .lang:hover:after, #site-navigation nav .languages .lang.current:after {
    width: 100%;
  }
}
@media (max-width: 760px) {
  #site-navigation #nav-toggle {
    display: block;
  }
  #site-navigation nav {
    flex-direction: row;
    position: absolute;
    z-index: 99;
    top: calc(100% + 0.5rem);
    right: 0.5rem;
    background-color: #FFF;
    color: var(--color-text);
    box-shadow: -0.5em 0.5em 1rem 0 rgba(0, 0, 0, 0.1);
    transition: all 100ms ease;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate3d(1em, -1.5em, 0);
    -moz-transform: translate3d(1em, -1.5em, 0);
    -ms-transform: translate3d(1em, -1.5em, 0);
    transform: translate3d(1em, -1.5em, 0);
  }
  .nav-toggled #site-navigation nav {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  #site-navigation nav ul {
    padding: 0.25rem 0;
  }
  #site-navigation nav a {
    display: block;
    padding: 0.5rem 1rem;
    line-height: 1.75rem !important;
    text-decoration: none;
    color: var(--color-text);
    white-space: nowrap;
  }
  #site-navigation nav li.current a,
#site-navigation nav li a:hover {
    color: var(--color-1);
  }
  #site-navigation nav .languages a,
#site-navigation nav .languages span {
    display: inline-block;
  }
  #site-navigation nav .languages span {
    font-weight: var(--font-weight-strong);
  }
}
#footer {
  margin-top: 4rem;
  background-color: white;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
}
#footer table {
  margin-bottom: 2rem;
}
#footer tr {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
#footer tr td {
  min-width: 180px;
}
#footer tr td img {
  margin: 0 auto;
  width: auto;
  height: auto;
  max-width: 180px;
  max-height: 125px;
}
#footer small {
  display: block;
  max-width: 600px;
  margin: 3rem auto 0;
  text-align: center;
}
#footer small img {
  margin: 0 auto 0.5em;
}

.content ul {
  margin: 1.5em 0;
  padding-left: 1em;
}
.content ul li {
  line-height: 1.5em;
  list-style-type: square;
}
.content ol li {
  line-height: 1.5em;
}
.content h3 + ul {
  margin-top: 0;
}
.content figure {
  position: relative;
}
.content figure img {
  margin-bottom: 1.5rem;
}
.content figure figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.1em 0.5em;
  font-size: 0.9em;
}
.content img {
  width: 100%;
  margin-bottom: 1rem;
}
.content .images {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 460px) {
  .content .images {
    flex-direction: row;
  }
  .content .images.childs-1 img {
    width: 100%;
  }
  .content .images.childs-2 img {
    width: calc(50% - 0.5rem);
  }
  .content .images.childs-3 img {
    width: calc(33% - 0.5rem);
  }
  .content .images.childs-4 img {
    width: calc(25% - 0.667rem);
  }
}
.content .align-left {
  float: left;
  width: calc(50% - 0.5rem);
  margin: 0 1rem 1rem 0;
}
.content .align-left:nth-child(even) {
  margin-right: 0;
}
.content .align-right {
  float: right;
  width: calc(50% - 0.5rem);
  margin: 0 0 1rem 1rem;
}
.content .video {
  display: block;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
}
.content .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.svg-map {
  position: absolute;
  background-color: #BBD5EA;
  user-select: none;
}

.svg-map-zoom-buttons {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  display: flex;
  flex-direction: column;
}
.svg-map-zoom-buttons button {
  margin-bottom: 0.75rem;
  padding: 0;
  font-size: 1.25rem;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  color: #444;
  background-color: white;
  border: none;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
}
.svg-map-zoom-buttons button:hover {
  color: #000;
}

.zone {
  pointer-events: none;
}

.sub-zone {
  font-size: 12px;
}

.zone-title {
  font-size: 1.778em;
  line-height: 1.2em;
  text-transform: lowercase;
  margin-bottom: 0;
  white-space: nowrap;
  transition: all 0ms ease;
}
.zone-title:hover {
  text-decoration: underline;
  transition-duration: 75ms;
}
.zone-title:hover .label {
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.2);
}

.zone-title-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  pointer-events: auto;
  -webkit-user-drag: none;
  transition: all 75ms ease;
  cursor: pointer;
}
.zone-title-link:hover {
  transition: all 0ms ease;
}

.themes {
  display: flex;
  flex-wrap: wrap;
}

.theme-label {
  background: #FFF;
  color: #000;
  padding: 0.15em 0.75em;
  border-radius: 1em;
  font-size: 0.65em;
  line-height: 1.8em;
  user-select: none;
  text-decoration: none;
  pointer-events: auto;
  white-space: nowrap;
  transition: all 75ms ease;
  font-size: 0.556em;
  margin-top: 0.3rem;
  margin-right: 0.25rem;
  -webkit-user-drag: none;
  cursor: pointer;
  transition: all 0ms ease;
}
.theme-label:hover {
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.2);
  transition-duration: 75ms;
}
.theme-label .theme-label-text {
  display: inline-block;
  margin-left: 0.5em;
  border-radius: 1em 0 0 1em;
  font-weight: var(--font-weight-strong);
}

#easter-egg {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 6rem;
  height: 4rem;
  text-align: center;
  line-height: 4rem;
  font-size: 2rem;
  transform: translate(-1em, -1em);
  background: var(--color-bg);
  border-radius: 10px;
}

@media (max-width: 760px) {
  #info-panel.non-embedded {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.8);
    overflow: auto;
    text-align: center;
    z-index: 200;
  }
  #info-panel.non-embedded:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  #info-panel.non-embedded #info-panel-inner {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
    max-width: 720px;
    text-align: left;
    background-color: #FFF;
  }
  #info-panel.non-embedded #buttons-bar {
    position: relative;
  }
}
@media (min-width: 761px) {
  #info-panel.non-embedded {
    position: absolute;
    top: 0.75rem;
    left: 0;
    bottom: 0.75rem;
    width: 600px;
    max-width: 65%;
    z-index: 200;
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    background-color: #FFF;
    transition: all 200ms ease;
  }
  #info-panel.non-embedded.collapsed {
    transform: translateX(-100%);
  }
  #info-panel.non-embedded.practice {
    width: calc(100% - 0.75rem);
    max-width: 960px;
  }
  #info-panel.non-embedded #buttons-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 201;
  }
  #info-panel.non-embedded.collapsed #buttons-bar {
    left: 1.8rem;
  }
  #info-panel.non-embedded .button-close {
    right: 1.8rem;
  }
}
@media (min-width: 761px) and (max-width: 760px) {
  #info-panel.non-embedded .button-close {
    right: 0;
  }
}
@media (min-width: 761px) {
  #info-panel.non-embedded #info-panel-viewport {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    border-top: 1.8rem solid transparent;
    overflow-y: auto;
  }
}
@media (min-width: 761px) {
  #info-panel.non-embedded #info-panel-content {
    width: 100%;
    min-height: 100%;
  }
}

#info-panel.embedded {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.8);
  overflow: auto;
  text-align: center;
  z-index: 200;
  padding: 0;
  border: 1px solid #eee;
}
#info-panel.embedded:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
#info-panel.embedded #info-panel-inner {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%;
  max-width: 720px;
  text-align: left;
  background-color: #FFF;
}
#info-panel.embedded #buttons-bar {
  position: relative;
}
#info-panel.embedded #info-panel-inner {
  max-width: none;
  height: 100%;
}
#info-panel.embedded #info-panel-viewport {
  height: 100%;
}

#info-panel-header {
  padding: 1rem;
}
#info-panel-header .info-panel-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;
}
#info-panel-header .info-panel-title .label {
  margin-top: 1rem;
  margin-right: 0.75rem;
}

#info-panel-body {
  padding: 1rem;
}

.tool-metas {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  flex-wrap: wrap;
}

.tool-meta {
  margin-right: 1.5em;
  margin-bottom: 0.75em;
}
.tool-meta:before {
  font-family: "FontAwesome";
  margin-right: 0.25em;
  vertical-align: middle;
}
.tool-meta.group_size:before {
  content: "\f1ae";
}
.tool-meta.duration:before {
  content: "\f017";
}
.tool-meta.facilitator_participant:before {
  content: "\f0c0";
}
.tool-meta.experience_level:before {
  content: "\f005";
}
.tool-meta.online_offline:before {
  content: "\f108";
}

.tool-meta-name {
  display: inline-block;
  white-space: nowrap;
  margin-right: 0.5em;
  margin-bottom: 0.25em;
  text-transform: lowercase;
}

.tool-meta-value {
  background: var(--color-1);
  color: #FFF;
  padding: 0.15em 0.75em;
  border-radius: 1em;
  font-size: 0.65em;
  line-height: 1.8em;
  user-select: none;
  text-decoration: none;
  pointer-events: auto;
  white-space: nowrap;
  transition: all 75ms ease;
  font-size: 0.8em;
}
.color-1 .tool-meta-value {
  background: var(--color-1);
}
.color-2 .tool-meta-value {
  background: var(--color-2);
}
.color-3 .tool-meta-value {
  background: var(--color-3);
}
.color-4 .tool-meta-value {
  background: var(--color-4);
}

.short-tool-metas {
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  flex-wrap: wrap;
}

.short-tool-meta {
  margin-top: 0.5em;
  margin-right: 1.25em;
}
.short-tool-meta:before {
  font-family: "FontAwesome";
  margin-right: 0.25em;
  color: #000;
}
.color-1 .short-tool-meta:before {
  color: var(--color-1);
}
.color-2 .short-tool-meta:before {
  color: var(--color-2);
}
.color-3 .short-tool-meta:before {
  color: var(--color-3);
}
.color-4 .short-tool-meta:before {
  color: var(--color-4);
}
.short-tool-meta.group_size:before {
  content: "\f1ae";
}
.short-tool-meta.duration:before {
  content: "\f017";
}
.short-tool-meta.facilitator_participant:before {
  content: "\f0c0";
}
.short-tool-meta.experience_level:before {
  content: "\f005";
}
.short-tool-meta.online_offline:before {
  content: "\f108";
}

.tool figure {
  position: relative;
  margin: 0 -1rem;
  max-width: none;
}
.tool figure img {
  margin-bottom: 1.5rem;
}
.tool figure figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.1em 0.5em;
  font-size: 0.9em;
}
.tool .buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}
.tool .buttons h3 {
  margin-top: 1rem;
}
.tool .buttons .button {
  margin-block: 0.25rem 0.75rem;
}

.tool-filters h3 + .button-clear {
  margin-top: 0.5em;
}

.tool-filter {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}
.tool-filter:last-child {
  margin-bottom: 0.3rem;
}
.tool-filter h4 {
  text-transform: lowercase;
  font-size: 0.85em;
}
.tool-filter h4:before {
  font-family: "FontAwesome";
  margin-right: 0.5em;
  vertical-align: middle;
}
.tool-filter.group_size h4:before {
  content: "\f1ae";
}
.tool-filter.duration h4:before {
  content: "\f017";
}
.tool-filter.facilitator_participant h4:before {
  content: "\f0c0";
}
.tool-filter.experience_level_facilitator h4:before {
  content: "\f005";
}
.tool-filter.online_offline h4:before {
  content: "\f108";
}

.tool-filter-options li {
  background: var(--color-bg);
  color: var(--color-text);
  padding: 0.15em 0.75em;
  border-radius: 1em;
  font-size: 0.65em;
  line-height: 1.8em;
  user-select: none;
  text-decoration: none;
  pointer-events: auto;
  white-space: nowrap;
  transition: all 75ms ease;
  display: inline-block;
  margin: 0.2em 0.4em 0.2em 0;
  cursor: pointer;
}
.tool-filter-options li:hover {
  background-color: #000;
  color: #FFF;
}
.tool-filter-options li.selected {
  background-color: var(--color-1);
  color: #FFF;
}

.panel.practices h4 {
  margin-top: 0.6rem;
  font-size: 0.85em;
}
.panel.practices a {
  text-decoration: none;
}
.panel.practices a:hover {
  text-decoration: underline;
}

.practice #practice-body {
  display: flex;
  flex-direction: column-reverse;
}
.practice #practice-body #practice-items {
  margin-top: 3rem;
  flex-shrink: 0;
  margin-right: 1rem;
}
.practice #practice-body #practice-items > li {
  position: relative;
  padding-bottom: 2.5rem;
  text-align: right;
}
.practice #practice-body #practice-items > li h2, .practice #practice-body #practice-items > li .subtitle {
  cursor: pointer;
  user-select: none;
}
.practice #practice-body #practice-items > li h2 {
  font-size: 1.15em;
  margin-bottom: 0;
}
.practice #practice-body #practice-items > li h2:before {
  content: " ";
  position: absolute;
  top: 0.55rem;
  right: -3px;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #FFF;
  border: 2px solid #000;
  border-radius: 50%;
}
.practice #practice-body #practice-items > li .subtitle {
  font-size: 0.9em;
}
.practice #practice-body #practice-items > li .practice-item-toggle {
  display: inline-block;
  padding-right: 1.25rem;
}
.practice #practice-body #practice-items > li .practice-item-toggle:hover {
  padding-right: 1.15rem;
  padding-left: 0.1rem;
  transition: all 100ms ease;
}
.practice #practice-body #practice-items > li .practice-item-toggle:hover h2:before {
  transform: scale(1.1);
  transition: all 100ms ease;
}
.practice #practice-body #practice-items > li:before {
  content: " ";
  position: absolute;
  top: 0.55rem;
  right: 0;
  width: 6px;
  height: 100%;
  background-color: var(--color-1);
}
.practice #practice-body #practice-items > li:last-child::before {
  content: none;
}
.practice #practice-body #practice-item {
  background-color: var(--color-bg-hover);
  padding: 1rem;
  min-height: 30vh;
  text-align: left;
}
.practice #practice-body #practice-item .button-close {
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0 0.575rem;
  cursor: pointer;
}
.practice #practice-body #practice-item .button-close .icon {
  width: 0.65rem;
  transition: all 100ms ease;
}
@media (max-width: 719px) {
  .practice #practice-item {
    position: relative;
    width: calc(100% - 1.25rem) !important;
    margin: 1rem 0;
  }
  .practice #practice-item:after {
    content: " ";
    position: absolute;
    bottom: 100%;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--color-bg-hover);
  }
}
@media (min-width: 720px) {
  .practice #practice-body {
    flex-direction: row;
  }
  .practice #practice-intro {
    margin-top: 3rem;
    padding: 0 1.5rem;
  }
  .practice #practice-items {
    width: 30%;
    min-width: 300px;
  }
  .practice #practice-item {
    position: absolute;
    top: -86px;
    left: calc(100% + 20px);
    margin-bottom: 2rem;
  }
  .practice #practice-item:after {
    content: " ";
    position: absolute;
    top: 90px;
    right: 100%;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--color-bg-hover);
  }
}

html, body, #root {
  width: 100%;
  height: 100%;
  position: fixed;
}

.practices ul {
  padding-left: 0.5rem;
  font-size: 0.85rem;
}
.practices ul li {
  margin-top: 0.5rem;
}

