
body {
  margin: 0;
  background-color: var(--color-bg);
  font-family: var(--font-family-default);
  font-weight: var(--font-weight-default);
  font-size: 16px;
  color: var(--color-text);

  @media (min-width: 600px) {
    font-size: 17px;
  }
}
em {
  font-style: italic;
}
strong, th {
  font-weight: var(--font-weight-strong);
}

h1, h2, h3, h4, h5 {
  font-family: var(--font-family-headings);
  font-weight: var(--font-weight-headings);
  color: var(--color-headings);
  margin: 0;

  .icon {
    font-family: "FontAwesome";
    margin-right: 0.5em;
    vertical-align: middle;
    font-size: 0.9em;
  }

  &.subtitle {
    font-family: var(--font-family-default);
    font-weight: var(--font-weight-default);
    margin-bottom: 1rem;
  }
}
h1 {
  font-size: 2em;
  line-height: 1.25em;
  margin-bottom: 1em;
  //text-transform: lowercase;
}
h2 {
  font-size: 1.3em;
  margin-bottom: 1em;
  line-height: 1.25em;
}
h3, h4, h5 {
  font-size: 1em;
  line-height: 1.35em
}

p {
  margin: 0 0 1.5em;
  line-height: 1.5em;

  &:last-child  {
    margin-bottom: 0;
  }
}

small,
.small {
  font-size: 0.8em;
  line-height: 1.35em;
}

.description {
  margin-bottom: 2em;
}

.nowrap {
  white-space: nowrap;
}

:focus {
  outline: 0;
}
::selection {
  background: #000;
  color: #FFF;
}

.fa-icon {
  display: inline-block;
  font-family: "FontAwesome";
  transition: all 100ms ease;
}