
@import "_resets";
@import "_variables";
@import "_fonts";
@import "_typography";
@import "_links";
@import "_forms";
@import "_label";
@import "_ui-elements";

@import "_layout";
@import "_header";
@import "_footer";
@import "_content";
@import "_metro";
@import "_info-panel";
@import "_tool";
@import "_tool-filter";
@import "_practice-timeline";


html, body, #root {
  width: 100%;
  height: 100%;
  position: fixed;
}

// div {
//   box-sizing: border-box;
// }
// img {
//   max-width: 100%;
//   height: auto;
// }

.practices {
  ul {
    padding-left: 0.5rem;
    font-size: 0.85rem;

    li {
      margin-top: 0.5rem;
    }
  }
}